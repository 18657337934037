import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { useAppData } from 'src/client/helpers/useAppData';

export const MainFooter = () => {
  const { commit = '', site, version = '', countryData } = useAppData();
  const dataString = `${commit} ${version}`.trim();

  const isCalifornia = countryData?.country === 'US' && countryData?.state === 'CA';

  return (
    <Footer>
      <p>
        <FormattedMessage id="footer.text.privacy_is_important" />
        <br />
        <FormattedMessage
          id="footer.text.segpay"
          values={{
            a: (chunks) => (
              <a rel="noopener noreferrer" target="_blank" href="https://cs.segpay.com">
                {chunks}
              </a>
            ),
          }}
        />
      </p>
      <span>
        <a href={`https://www.${site?.domain}/2257`} rel="noopener noreferrer" target="_blank">
          <FormattedMessage id="footer.links.18-usc-2257" />
        </a>
      </span>
      {isCalifornia && (
        <span>
          <a
            href="https://help.metart.network/policies-rules/privacy-policy#PrivacyPolicy-UpdatingorDeletingYourPersonalInformation"
            rel="noopener noreferrer"
            target="_blank"
          >
            Do Not Sell or Share My Personal Information
          </a>
        </span>
      )}
      <p>
        <FormattedMessage
          id="footer.text.adult_material_warning"
          values={{ siteName: site?.name, copyrightYear: new Date().getFullYear() }}
        />
      </p>
      <span>
        <a href="https://help.metart.network/policies-rules/terms-conditions" rel="noopener noreferrer" target="_blank">
          <FormattedMessage id="footer.links.terms_conditions" />
        </a>
      </span>
      <span>
        <a href="https://help.metart.network/policies-rules/privacy-policy" rel="noopener noreferrer" target="_blank">
          <FormattedMessage id="footer.links.privacy_policy" />
        </a>
      </span>
      <span>
        <a href="https://metart.atlassian.net/servicedesk/customer/portals" rel="noopener noreferrer" target="_blank">
          <FormattedMessage id="footer.links.customer_support" />
        </a>
      </span>
      <span className="data">{dataString}</span>
    </Footer>
  );
};

const Footer = styled.section`
  font-size: 0.6rem;
  padding-bottom: 48px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 0.8rem;
  }

  span {
    display: block;
    font-size: 0.8rem;
    margin: 4px 0;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  span,
  p {
    a {
      color: #000;
    }
  }

  .data {
    color: dimgray;
    font-size: 0.7rem;
    margin-top: 12px;
  }
`;
