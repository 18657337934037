import React from 'react';
import styled from 'styled-components/macro';
import {
  AffiliateCode,
  createAffiliateURL,
  getAffiliateCodeForRedirect,
} from '@tovia/man-app-utils/lib/helpers/affiliate';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { Purchase } from '@tovia/man-app-utils/lib/helpers/purchase';
import { ConstrainedContainer } from 'src/client/helpers/mediaQueries';
import { useAppData } from '../../helpers/useAppData';

type Props = {
  abbreviation: string;
  description: string;
  domain: string;
  name: string;
  networkUUID: string;
  siteUUID: string;
  tags: string[];
};

export const SiteInfo = (props: Props) => {
  const { abbreviation, description, domain, name, networkUUID, siteUUID, tags } = props;
  const { cdnUrl, firstPurchase, accessSubdomain, siteSubdomain } = useAppData();

  const loginLink = getLoginLink({ accessSubdomain, siteSubdomain, domain, firstPurchase, siteUUID });

  return (
    <Wrapper>
      <Card>
        <img src={`${cdnUrl}/logos/${networkUUID}/${abbreviation}@2x.png`} alt="" />
        <span>
          <a href={loginLink} target="_blank" rel="noopener noreferrer">
            Go to Site
            <img src={`${cdnUrl}/metcart/visit.svg`} alt="" />
          </a>
        </span>
      </Card>
      <Data>
        <Info>
          <h3>{name}</h3>
          <p>{description}</p>
        </Info>
        <Bar />
        <Tags>
          {tags.map((tag) => (
            <span key={tag}>{tag}</span>
          ))}
        </Tags>
      </Data>
    </Wrapper>
  );
};

const Wrapper = styled(ConstrainedContainer)`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Card = styled.div`
  background-color: #3c4142;
  flex: 1 0 auto;
  height: 180px;
  margin: 0 auto;
  position: relative;
  width: 180px;

  @media (min-width: 1024px) {
    height: 268px;
    width: 390px;
  }

  > img {
    padding: 0 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media (min-width: 1024px) {
      display: block;
      height: 48px;
      margin: 0;
      width: unset;
    }
  }

  > span {
    bottom: 12px;
    display: inline-block;
    font-size: 10px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;

    a {
      color: inherit;
      text-decoration: none;
    }

    img {
      margin: 0 6px;
      transform: translateY(1px);
      width: 12px;
    }
  }
`;

const Data = styled.div`
  flex-grow: 1;
  margin-left: 20px;
`;

const Info = styled.div`
  > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 32px 0 12px;

    @media (min-width: 768px) {
      margin: 0 0 12px;
    }

    @media (min-width: 1024px) {
      font-size: 40px;
    }
  }

  > p {
    font-size: 14px;
    line-height: 1.6;
    margin: 0;

    @media (min-width: 1024px) {
      font-size: 16px;
      line-height: 1.8;
    }
  }
`;

const Bar = styled.hr`
  border-color: #3c4142;
  margin: 16px 0;
`;

const Tags = styled.span`
  color: #b1b5bb;

  > span {
    background-color: #3c4142;
    border-radius: 4px;
    display: inline-block;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 0 10px 10px 0;
    padding: 0 12px;

    @media (min-width: 1024px) {
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      padding: 0 24px;
    }
  }
`;

type GetLoginLinkParams = {
  accessSubdomain: string;
  domain: string;
  firstPurchase: Purchase | undefined;
  siteSubdomain: string;
  siteUUID: string;
};

const getLoginLink = ({ accessSubdomain, domain, firstPurchase, siteSubdomain, siteUUID }: GetLoginLinkParams) => {
  // The external sites will have their full affiliate link set as the 'domain'
  if (domain.startsWith('https://')) {
    return domain;
  }

  let affiliateCode: AffiliateCode | undefined;
  if (firstPurchase) {
    affiliateCode = getAffiliateCodeForRedirect(firstPurchase, addDashesToUUID(siteUUID));
  }

  return createAffiliateURL({
    accessSubdomain,
    affiliateCode,
    path: 'login',
    siteDomain: domain.toLowerCase(),
    siteSubdomain,
  });
};
