import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { SectionTitle } from '../../pages/PreJoinPageRedesign/SectionTitle';
import { SectionContainer } from '../../pages/PreJoinPageRedesign/SectionContainer';
import { GetPricesResponse, JoinPageProduct } from '../../../shared/types';
import { SubscriptionPlanCard } from './SubscriptionPlanCard';
import { PaygardenPanel } from '../../pages/PreJoinPage/PaygardenPanel';
import { PayByCheckPanel } from '../../pages/PreJoinPage/PayByCheckPanel';
import { SubscriptionPlanCardPlaceholder } from '../Placeholder/SubscriptionPlanCardPlaceholder';
import { mediaBreakpoints } from '../../helpers/mediaQueries';
import { useIsMobile } from '../../helpers/useIsMobile';
import { SubscriptionButton } from '../../pages/PreJoinPage/SubscriptionOption';

type Props = {
  activePayment?: AltPayment;
  disabled: boolean;
  getPricesResponse?: GetPricesResponse;
  onSelectPlan: (priceGroupProductID: number, paymentOptionOverride?: AltPayment) => void;
  signalPayByCheck: () => void;
};

export const SubscriptionPlans = (props: Props) => {
  const { activePayment, disabled, getPricesResponse, onSelectPlan, signalPayByCheck } = props;
  const { paymentPrices } = getPricesResponse || {};

  const isMobile = useIsMobile();
  const intl = useIntl();

  const showPaygardenPanel = activePayment?.billerID === 9;
  const showPayByCheckPanel = activePayment?.billerID === 12;

  const [subOption, setSubOption] = useState<number | undefined>(undefined);

  const groupedSubscriptions = useMemo(() => {
    const subscriptions =
      paymentPrices?.find((r) => r.billerID === activePayment?.billerID)?.subscriptionPlans ||
      paymentPrices?.[0].subscriptionPlans ||
      undefined;

    // Reset selected sub in case new list has no matching product
    if (subOption && !subscriptions?.some((sub) => sub.priceGroupProductID === subOption)) {
      setSubOption(undefined);
    }
    if (!subscriptions) {
      return null;
    }

    return subscriptions.reduce((acc, value) => {
      const priceGroupName = value.priceGroupName?.replace(/\s/gi, '-').toLowerCase();

      if (!priceGroupName) return acc;

      if (!acc[priceGroupName]) {
        acc[priceGroupName] = [];
      }

      acc[priceGroupName].push(value);

      return acc;
    }, {} as Record<string, JoinPageProduct[]>);
  }, [activePayment?.billerID, paymentPrices, subOption]);

  // Set default selected subscription
  useEffect(() => {
    if (subOption || !groupedSubscriptions) {
      return;
    }
    const idx = Object.keys(groupedSubscriptions)[0];
    setSubOption(groupedSubscriptions[idx][0].priceGroupProductID);
  }, [groupedSubscriptions, subOption]);

  const onSelectPlanClick = (priceGroupProductID: number) => {
    if (disabled) {
      return;
    }

    if (!isMobile) {
      onSelectPlan(priceGroupProductID);
    }

    setSubOption(priceGroupProductID);
  };

  if (showPaygardenPanel) return <PaygardenPanel />;

  if (showPayByCheckPanel) return <PayByCheckPanel onClick={signalPayByCheck} />;

  const loading = !groupedSubscriptions;

  return (
    <SectionContainer>
      <SectionTitle stepNumber={3} text={intl.formatMessage({ id: 'subscription.choice.redesign.title' })} />
      <SubscriptionsWrapper>
        {loading && (
          <>
            <SubscriptionPlanCardPlaceholder />
            <SubscriptionPlanCardPlaceholder />
            <SubscriptionPlanCardPlaceholder />
          </>
        )}
        {!loading && (
          <>
            {Object.keys(groupedSubscriptions).map((key) => {
              const group = groupedSubscriptions[key];
              return group.map((item) => {
                const isActive = subOption === item.priceGroupProductID;

                return (
                  <SubscriptionPlanCard
                    isActive={isActive}
                    isMobile={isMobile}
                    item={item}
                    key={item.priceGroupProductID}
                    loading={disabled}
                    onClick={() => onSelectPlanClick(item.priceGroupProductID)}
                  />
                );
              });
            })}
            {isMobile && (
              <CheckoutButton
                active={!!subOption}
                loading={disabled}
                {...(subOption ? { onClick: () => onSelectPlan(subOption) } : {})}
              >
                <FormattedMessage id="subscription.choice.redesign.checkout" />
              </CheckoutButton>
            )}
          </>
        )}
      </SubscriptionsWrapper>
    </SectionContainer>
  );
};

const SubscriptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;

  @media ${mediaBreakpoints.md} {
    flex-direction: row;
    gap: 8px;
  }
`;

const CheckoutButton = styled(SubscriptionButton)`
  width: 100%;
  margin-top: 12px;
`;
