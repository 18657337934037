import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ApiClient, useAppData } from 'src/client/helpers';
import { apiUrls } from 'src/client/helpers/constants';
import { Biller } from 'src/shared/constants/billers';

type UserBillingData = {
  billerId?: number;
  PA?: string;
  siteUUID?: string;
  subscriptionUUID?: string;
  extSubscriptionID?: string;
  extProdictID?: string;
};

type UseCreateOneClickArgs = {
  userBillingData: UserBillingData[];
};

export const useCreateOneClickURL = ({ userBillingData }: UseCreateOneClickArgs): string[] => {
  const [iFrameURLs, setIFrameURLs] = useState<string[]>([]);
  const [cookies] = useCookies(['mamident']);

  const { site, user } = useAppData();

  useEffect(() => {
    if (!user) {
      // eslint-disable-next-line no-console
      console.error('User is not defined, unable to create oneClick URLs without user email!');
      return;
    }

    const postOneClickData = async (billingData: UserBillingData) => {
      const data = {
        ...billingData,
        email: user.email,
        mamIdent: cookies['mamident'],
        paramsJSON: null,
        siteUUID: billingData.siteUUID || site.UUID,
      };

      const api = new ApiClient();
      try {
        const response = await api.post(apiUrls.post.createOneClickURL, { data });
        return response.body.url;
      } catch (err) {
        window.dataLayer.push({
          event: 'gaEvent',
          eventCategory: 'failed_oneclick_registration',
          eventLabel: billingData.subscriptionUUID,
        });
        // eslint-disable-next-line no-console
        console.error('Request failed to receive oneClick URL');
        return null;
      }
    };

    const fetchAllURLs = async () => {
      try {
        const urls = await Promise.all(
          userBillingData.map(async (billingData) => {
            if (billingData.billerId === Biller.CCBill || billingData.billerId === Biller.Epoch) {
              return await postOneClickData(billingData);
            } else {
              return null;
            }
          }),
        );

        setIFrameURLs(urls.filter((url) => Boolean(url)));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching oneClick URLs', error);
      }
    };

    fetchAllURLs();
  }, [userBillingData, user, site, cookies]);

  return iFrameURLs;
};
