import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import { useAppData } from '../../helpers';
import { PaymentPrice } from '../../../shared/types';
import { makeUrlSafeName } from '../../pages/PreJoinPage/makeUrlSafeName';
import { mediaBreakpoints } from '../../helpers/mediaQueries';

type Props = {
  isActive?: boolean;
  className?: string;
  loading?: boolean;
  payment?: PaymentPrice;
  paymentOption?: AltPayment;
  onClick?: () => void;
};

const CCPaymentContent = () => {
  const { cdnUrl } = useAppData();

  return (
    <>
      <img src={`${cdnUrl}/payment/small/visa.svg`} alt="VISA" />
      <img src={`${cdnUrl}/payment/small/mastercard.svg`} alt="MasterCard" />
      <img src={`${cdnUrl}/payment/small/discover.svg`} alt="Discover" />
      <img src={`${cdnUrl}/payment/small/jcb.svg`} alt="JCB" />
    </>
  );
};

const SpriteContent = (props: { paymentOption?: AltPayment; paymentType?: string }) => {
  const { paymentOption, paymentType } = props;

  const { cdnUrl } = useAppData();

  const sprite = makeUrlSafeName(paymentType || '');

  return <img src={`${cdnUrl}/payment/${sprite}.svg`} alt={paymentOption?.name} />;
};

export const PaymentCard = (props: PropsWithChildren<Props>) => {
  const { children, className, isActive, onClick, payment, paymentOption } = props;
  const { paymentType } = payment || {};

  return (
    <PaymentCardWrapper isActive={isActive} className={className} onClick={onClick}>
      {(paymentType === 'Credit Card' && <CCPaymentContent />) ||
        (payment && <SpriteContent paymentOption={paymentOption} paymentType={paymentType} />)}
      {children}
    </PaymentCardWrapper>
  );
};

const PaymentCardWrapper = styled.div`
  position: relative;
  height: 48px;
  border-radius: 2px;
  border: 1px solid ${({ isActive }) => (isActive ? '#1E90FF' : 'rgba(0, 0, 0, 0.3)')};
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 9px;
  gap: 4px;
  max-height: 48px;

  img {
    &:nth-child(n + 4) {
      display: none;

      @media ${mediaBreakpoints.md} {
        display: inline;
      }
    }
  }

  @media ${mediaBreakpoints.md} {
    width: 250px;
    padding: 12px 21px;
  }

  img {
    filter: ${({ isActive }: { isActive?: boolean }) => (isActive ? 'grayscale(0)' : 'grayscale(1)')};
  }
`;
