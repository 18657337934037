import isEmail from 'validator/lib/isEmail';
import React, { useState } from 'react';
import { ApiClient } from '../../helpers';
import { apiUrls } from '../../helpers/constants';

type HookParams = {
  email: string;
  emailOnBlurCallback?: () => void;
  setValidEmail: React.Dispatch<React.SetStateAction<string | boolean>>;
  toggleUserExists: (userExists: boolean) => void;
};

export const useEmailOnBlur = ({ email, emailOnBlurCallback, setValidEmail, toggleUserExists }: HookParams) => {
  const [customError, setCustomError] = useState('');
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [previousEmail, setPreviousEmail] = useState('');

  const emailOnBlur = () => {
    if (email === previousEmail || isValidatingEmail) {
      return;
    }

    setPreviousEmail(email);

    emailOnBlurCallback?.();
    validateEmail();
  };

  const validateEmail = async () => {
    const valid = isEmail(email, { allow_utf8_local_part: false });
    setValidEmail(valid);

    if (valid) {
      setIsValidatingEmail(true);
      try {
        const api = new ApiClient();
        const {
          body: { account },
        } = await api.post(apiUrls.post.account, {
          data: {
            email,
          },
        });
        toggleUserExists(account);
      } catch (e: unknown) {
        const error = e as Error;
        setValidEmail(false);
        setCustomError(
          error.message === 'Bad Request'
            ? 'Too many tries, please try again later'
            : 'There was an error checking availability, please try again',
        );
      }
      setIsValidatingEmail(false);
    }
  };

  return { customError, emailOnBlur, isValidatingEmail };
};
