import React from 'react';
import styled from 'styled-components/macro';
import Slider from 'react-slick';
import {
  AffiliateCode,
  createAffiliateURL,
  getAffiliateCodeForRedirect,
} from '@tovia/man-app-utils/lib/helpers/affiliate';
import { addDashesToUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { MarketingDataSite } from 'src/shared/constants/marketingData';
import { Placeholder } from '../../components/Placeholder/Placeholder';
import { PlaceholderLine } from '../../components/Placeholder/PlaceholderLine';
import { PlaceholderImage } from '../../components/Placeholder/PlaceholderImage';
import { useAppData } from '../../helpers';

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 6000,
  dots: true,
  infinite: true,
  pauseOnHover: false,
  speed: 1250,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const placeholders = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6'];

type Props = {
  cdnUrl: string;
  className: string;
  onClick: (siteUUID: string) => void;
  selected: string[];
  sites: MarketingDataSite[];
};

function SiteChoiceContainer(props: Props) {
  const { firstPurchase, accessSubdomain, siteSubdomain } = useAppData();
  const { cdnUrl, className, onClick, selected, sites } = props;

  return (
    <div className="row">
      {!sites.length &&
        placeholders.map((placeholder) => (
          <div key={placeholder} className={`${className} sales-column col-sm-6`}>
            <div className="sale-wrapper">
              <Placeholder>
                <div className="sale-slider">
                  <PlaceholderImage className="placeholder-slider" />
                </div>
                <div className="site-info">
                  <PlaceholderLine />
                  <PlaceholderLine long />
                  <PlaceholderLine />
                  <PlaceholderLine long />
                  <PlaceholderLine medium />
                </div>
                <PlaceholderImage className="placeholder-button" />
              </Placeholder>
            </div>
          </div>
        ))}
      {!!sites.length &&
        sites.map((site) => {
          const active = selected.find((item) => item === site.UUID);

          let affiliateCode: AffiliateCode | undefined;
          if (firstPurchase) {
            affiliateCode = getAffiliateCodeForRedirect(firstPurchase, addDashesToUUID(site.UUID));
          }

          const loginLink = createAffiliateURL({
            accessSubdomain,
            affiliateCode,
            siteDomain: site.domain.toLowerCase(),
            siteSubdomain,
            path: 'login',
          });

          return (
            <div key={site.UUID} className={`${className} sales-column col-sm-6`}>
              <div className="sale-wrapper">
                {!site.available && (
                  <div className="purchased">
                    <p className="purchased-message">
                      Thank you for purchasing a subscription to
                      <br />
                      {site.name}
                    </p>
                  </div>
                )}
                <div className="sale-slider">
                  <Slider {...settings}>
                    <img className="sale-image" src={`${cdnUrl}/slider/${site.abbreviation}/1.jpg`} alt="" />
                    <img className="sale-image" src={`${cdnUrl}/slider/${site.abbreviation}/2.jpg`} alt="" />
                    <img className="sale-image" src={`${cdnUrl}/slider/${site.abbreviation}/3.jpg`} alt="" />
                    <img className="sale-image" src={`${cdnUrl}/slider/${site.abbreviation}/4.jpg`} alt="" />
                    <img className="sale-image" src={`${cdnUrl}/slider/${site.abbreviation}/5.jpg`} alt="" />
                  </Slider>
                  <a className="sale-visit" href={loginLink} target="_blank" rel="noopener noreferrer">
                    <span className="text">visit site</span>
                    <img src={`${cdnUrl}/metcart/visit.svg`} alt="" />
                  </a>
                </div>
                <p className="site-info">
                  <span className="site-name">{site.name}</span>
                  {' - '}
                  <span>{site.description}</span>
                </p>
                <button
                  disabled={Boolean(active || !site.available)}
                  onClick={() => onClick(site.UUID)}
                  className="add-cart"
                  type="button"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export const SiteChoice = styled(SiteChoiceContainer).attrs(({ cdnUrl }) => ({
  manLogo: `${cdnUrl}/man-logo.svg`,
}))`
  & .sale-wrapper {
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    position: relative;

    @media (min-width: 768px) {
      padding: 20px;
    }
  }

  & .purchased {
    background-color: rgba(255, 255, 255, 0.8);
    background-image: ${(props) => `url(${props.manLogo})`};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .purchased-message {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 24px;
      position: relative;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & .sale-slider {
    position: relative;

    .sale-image {
      min-height: 133px;
    }

    .sale-visit {
      color: #fff;
      position: absolute;
      right: 0;
      text-decoration: none;
      text-transform: uppercase;
      top: 8px;

      :hover {
        text-decoration: underline;
      }

      .text {
        display: none;

        @media (min-width: 768px) {
          display: inline-block;
        }
      }

      img {
        padding: 0 8px;
      }
    }
  }

  & .site-info {
    min-height: 100px;

    @media (min-width: 576px) {
      min-height: 152px;
    }

    @media (min-width: 768px) {
      min-height: 120px;
    }

    @media (min-width: 992px) {
      min-height: 100px;
    }

    .site-name {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  & .add-cart {
    background-color: #f4ba3a;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.15rem;
    height: 48px;
    width: 100%;

    :disabled {
      cursor: unset;
    }

    :focus {
      outline: none;
    }
  }

  & ${Placeholder} {
    & .image.placeholder-slider {
      padding-top: 200px;
    }

    & .image.placeholder-button {
      padding-top: 48px;
    }

    & .site-info {
      margin: 14px 0;
    }
  }
`;
