import React from 'react';
import styled, { css } from 'styled-components/macro';
import { CamModel } from '@tovia/man-protos/dist/types/cams.types';
import { useAppData } from 'src/client/helpers';
import { DESKTOP } from 'src/client/helpers/mediaQueries';

type Props = {
  cam: CamModel;
  camTrackingId?: string;
};

export const CamThumbnail = ({ cam, camTrackingId }: Props) => {
  const { site, siteSubdomain, showLegaLRestrictionSplash } = useAppData();

  const camTrackingSearchParam = camTrackingId ? `?${new URLSearchParams({ AFNO: camTrackingId }).toString()}` : '';
  const camLink = `https://${siteSubdomain}.${site.domain.toLowerCase()}/cams/${cam.name}${camTrackingSearchParam}`;

  return (
    <CamWrapper key={cam.id} href={camLink} target="_blank" rel="nofollow noopener noreferrer">
      <ImageWrapper>
        <CamImage src={cam.thumb} alt={cam.name} isBlurred={showLegaLRestrictionSplash} />
        <PerformerName>{cam.name}</PerformerName>
      </ImageWrapper>

      <HeadlineWrapper hidden={showLegaLRestrictionSplash}>
        <span>{cam.headline}</span>
      </HeadlineWrapper>
    </CamWrapper>
  );
};

const CamWrapper = styled.a`
  background: #111;
  border-radius: 3px;
  overflow: hidden;
  text-decoration: none;
`;

const CamImage = styled.img<{ isBlurred: boolean }>`
  width: 100%;
  display: block;
  object-fit: cover;
  aspect-ratio: 4 / 3;
  ${({ isBlurred }) =>
    isBlurred &&
    css`
      filter: blur(20px);
      clip-path: inset(0);
    `}
`;

const PerformerName = styled.h2`
  font-weight: normal;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  height: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: white;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  padding-bottom: 5px;

  @media ${DESKTOP} {
    font-size: 18px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const HeadlineWrapper = styled.p`
  color: #999999;
  font-size: 10px;
  margin: 0;
  padding: 5px 10px;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
